<template>
  <tj-dialog
    v-model="visible"
    :title="title"
    @close="onClose"
  >
    <el-form ref="formRef" label-width="auto" :model="form" :rules="rules">
      <el-form-item label="标题：" prop="title">
        <el-input v-model.trim="form.title"></el-input>
      </el-form-item>
      <el-form-item label="图片：">
        <el-image v-if="form.image_url" class="upload-image" :src="form.image_url"></el-image>
        <el-upload
          ref="upload"
          name="image"
          accept="image/*"
          :show-file-list="false"
          :action="uploadActionURL"
          :limit="1"
          :on-exceed="handleExceed"
          :on-success="handleSuccess"
        >
          <el-button>点击上传</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item label="描述：">
        <el-input v-model.trim="form.content" type="textarea"></el-input>
      </el-form-item>
      <el-form-item label="状态：">
        <el-radio-group v-model.number="form.state">
          <el-radio-button :label="1">启用</el-radio-button>
          <el-radio-button :label="0">禁用</el-radio-button>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </template>
  </tj-dialog>
</template>

<script setup>
import { ref, computed, nextTick } from 'vue';
import { ElMessage } from 'element-plus';
import { useDialog } from '@tj/ui';
import request from '@/hooks/request';

const emit = defineEmits(['submit']);

const defaultBannerInfo = {
  id: 0,
  title: '',
  image_id: 0,
  image_url: '',
  content: '',
  state: 1,
};

const form = ref({ ...defaultBannerInfo });
const formRef = ref();

const rules = {
  title: { required: true, message: '标题不能为空' },
};

const title = computed(() => {
  if (form.value.id) {
    return '编辑轮播图';
  }
  return '添加轮播图';
});

const { VUE_APP_BASE_API } = process.env;
const uploadActionURL = `${VUE_APP_BASE_API}/common/upload_image`;
const upload = ref();
const handleExceed = () => {
  ElMessage.error('仅可上传一张图片');
};
const handleSuccess = (res) => {
  if (res.status === 'success') {
    const { data: { id, url } } = res;
    form.value.image_id = id;
    form.value.image_url = url;
  } else {
    ElMessage.error(res.message);
    upload.value.clearFiles();
  }
};

const {
  visible,
  open,
  close,
  onOpen,
} = useDialog();
onOpen((id) => {
  if (id) {
    request.get(`/admin/banner/${id}`).then((data) => {
      form.value = data;
      if (data.image && data.image.url) {
        form.value.image_url = data.image.url;
      }
    });
  } else {
    form.value = { ...defaultBannerInfo };
  }
  nextTick(() => {
    formRef.value.clearValidate();
  });
});
function onClose() {
  form.value = { ...defaultBannerInfo };
  upload.value.clearFiles();
}

function submit() {
  formRef.value.validate((valid) => {
    if (valid) {
      const { id } = form.value;
      if (id) {
        request.patch(`/admin/banner/${id}`, form.value).then(() => {
          emit('submit');
          close();
        });
      } else {
        request.post('/admin/banner', form.value).then(() => {
          emit('submit');
          close();
        });
      }
    }
  });
}

defineExpose({
  open,
});
</script>

<style lang="less" scoped>
.upload-image {
  max-width: 200px;
  max-height: 200px;
}
</style>
